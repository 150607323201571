import bgPhoto from "../../../assets/Home/Hero/hero-bg.png";
import AI from "../../../assets/Home/Hero/Generative-AI-powered.png";
import playIcon from "../../../assets/Home/Hero/play-icon.svg";
import rightArrow from "../../../assets/Home/Hero/arrow-right-icon.svg";
import gif from "../../../assets/Home/Hero/gif.gif";

import gsap from "gsap";
import SplitTextJS from 'split-text-js';


import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";

import "./Hero.css";
import { useEffect, useState } from "react";

const Hero = ({ resize }) => {

  useEffect(() => {
    const titles = gsap.utils.toArray(".dynamic-text-wrapper p");
    const tl = gsap.timeline(); // Create a timeline

    titles.forEach((title, index) => {
      const splitTitle = new SplitTextJS(title);

      tl
        .from(splitTitle.chars, {
          opacity: 0,
          y: "-1rem",
          rotateX: -90,
          stagger: 0.02,
          delay:0
        }, "<")
        .to(splitTitle.chars, {
          opacity: 1,
          duration: 0.7,
        }, "<1")
        .to(splitTitle.chars, {
          opacity: 0,
          y: "1rem",
          rotateX: 90,
        }, "<1");
    });

    tl.repeat(-1); 
  }, []);




  return (
    <div
      className={`hero-container`}
      style={{ backgroundImage: `url(${bgPhoto})` }}
    >
      {/* <div className={`hero-main  ${resize && "resize-hero"}`}> */}
      <div className={`hero-main resize-hero`}>
        <div className="hero-main-left">
          <img
            style={{ borderRadius: "32px" }}
            width={200}
            src={AI}
            alt="Generative AI-powered"
          />

          <div className="hero-main-left-text">
            <div className="hero-main-left-text-main">
              <div className="hero-main-left-text-main-container">
                <motion.p
                  initial={{ opacity: 0, y: 100 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    ease: [0.76, 0, 0.24, 1],
                    duration: 1,
                    delay: 0.2,
                  }}
                >
                  Generating
                </motion.p>
              </div>
              <div className="hero-main-left-text-main-container bold">
                 <div className="dynamic-text-wrapper">
                  <p>faster test cases</p>
                  <p>smarter insights</p>
                  <p>faster test cases</p>
                  <p>smarter insights</p>
                  <p>faster test cases</p>
                  <p>smarter insights</p>
                  <p>faster test cases</p>
                  <p>smarter insights</p>
                 </div>
              </div>
              <div className="hero-main-left-text-main-container last-hero-text">
                <motion.p
                  initial={{ opacity: 0, y: 100 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    ease: [0.76, 0, 0.24, 1],
                    duration: 1,
                    delay: 0.4,
                  }}
                >
                  for testers!
                </motion.p>
              </div>
            </div>
            <p>
            BotGauge autonomously generates test cases in English with predictive test planning, automatically maintains the test cases with smart healing, and debugs using an AI agent
            </p>
            <div className="hero-main-buttons">
              <Link
                to="https://calendly.com/botgauge/30min"
                className="hero-main-button book-a-demo btn"
              >
                <p>Book a demo</p>
                <img src={playIcon} alt="play-icon" />
              </Link>
              <Link
                to="/contact"
                className="hero-main-button btn"
              >
                {/* <p>Get Started</p> */}
                <p>Contact Us</p>
                <img src={rightArrow} alt="right-arrow" />
              </Link>
            </div>
          </div>
        </div>

        <div className="hero-main-right">
          <img src={gif} alt="gif" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
