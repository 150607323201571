import { useState } from 'react';
import { motion } from 'framer-motion';
import JotformEmbed from 'react-jotform-embed';
import spinnerDots from '../../assets/svg-spinners-3-dots-move.svg';

import './ContactUs.css';


const Loader = () => {
    return (
        <div style={{display:"flex",alignItems:"center",justifyContent:"center",width:"100%",height:"88vh"}}>
            <img src={spinnerDots} alt="loading" />
        </div>
    )
}

const ContactUs = () => {
    const [jotformLoaded, setJotformLoaded] = useState(false);
    setTimeout(() => {
        setJotformLoaded(true);
    }, 2000);

    return (
        <motion.div
            className={`contact-container`}
            style={{minHeight:"100vh"}}
            initial={{ y: -10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ ease: [0.76, 0, 0.24, 1], duration: 1 }}
        >
            {!jotformLoaded && <Loader />} 
            {jotformLoaded && <JotformEmbed src="https://form.jotform.com/241921262647457" />}
        </motion.div>
    )
}

export default ContactUs;
