import React, { useEffect, useRef, useState } from 'react'
import { BlogData } from '../../utils/blogs';
import { useNavigate } from 'react-router-dom';

import bgPhoto from '../../assets/Home/Hero/hero-bg.png';

import { motion } from 'framer-motion';
import BlogTemplate from './BlogTemplate/BlogTemplate';

import './BlogsPage.css';

const BlogsPage = ({resize}) => {
    const recentBlogs = BlogData.slice(-3).reverse();

    const navigate = useNavigate();
    const postsPerPage = 6;
    const [currentPage, setCurrentPage] = useState(1);
    const bottomSectionRef = useRef(null);
    const [currentPageChange, setCurrentPageChange] = useState(false);

    const handleBlogClick = (slug) => {
        navigate(`/blogs/${slug}`);
        window.scrollTo(0, 0);
    };

    const getBlogDataById = (slug) => {
        return BlogData.find(blog => blog.slug === slug);
    }

    const scrollToTop = () => {
        if (bottomSectionRef.current) {
            bottomSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handlePreviousPage = () => {
        setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
        setCurrentPageChange(true); 
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage < totalPages ? currentPage + 1 : totalPages);
        setCurrentPageChange(true);
    };

    useEffect(() => {
        if (currentPageChange) {
            scrollToTop(); 
            setCurrentPageChange(false); 
        }
    }, [currentPage, currentPageChange]);

    // Logic to calculate total number of pages
    const totalPages = Math.ceil(BlogData.length / postsPerPage);

    // Logic to slice the BlogData array based on current page
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = BlogData.slice(indexOfFirstPost, indexOfLastPost);

    return (
        <motion.div 
            initial={{ y: -10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ ease:[0.76,0,0.24,1], duration:1 }}
            className='blogpage-container'>
            <div 
                // className={`blogs-page__top-section ${resize && 'resize-blog'}`}
                className={`blogs-page__top-section resize-blog`}
                style={{ backgroundImage: `url(${bgPhoto})` }} 
            >
                <div className='blogs-page__top-section__header'>
                    <h4><span style={{fontWeight:"800"}}>Recent</span> Blog posts</h4>
                </div>
                <div className='recent-blogs'>
                    <div className='recent-blogs-left'>
                        <BlogTemplate
                            onclick={() => handleBlogClick(recentBlogs[0].slug)}
                            imgHeight={'228px'}
                            imgWidth={'522px'}
                            img={recentBlogs[0].compImg}
                            {...getBlogDataById(recentBlogs[0].slug)}
                        />
                    </div>

                    <div  className='recent-blogs-right'>
                        <div onClick={() => handleBlogClick(recentBlogs[1].slug)} className='recent-blogs-right-row'>
                            <img src={recentBlogs[1].compImg} alt="recent blog" />
                            <div className='recent-blogs-right-row-des'>
                                <div>
                                    {/* <p className='blog-auth'>Auth</p> */}
                                    <h6>{recentBlogs[1].title}</h6>
                                    <p className='hidden-des'>{recentBlogs[1].des}</p>
                                </div>
                                <div className='blog-tags'>
                                    {recentBlogs[1].tags.map((tag, i) => (
                                        <span style={{ color: tag.textColor, backgroundColor: tag.bgColor }} key={i}>{tag.text}</span>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div onClick={() => handleBlogClick(recentBlogs[2].slug)} className='recent-blogs-right-row'>
                            <img src={recentBlogs[2].compImg} alt="recent blog" />
                            <div className='recent-blogs-right-row-des'>
                                <div>
                                    {/* <p className='blog-auth'>Auth</p> */}
                                    <h6>{recentBlogs[2].title}</h6>
                                    <p className='hidden-des'>{recentBlogs[2].des}</p>
                                </div>
                                <div className='blog-tags'>
                                    {recentBlogs[2].tags.map((tag, i) => (
                                        <span style={{ color: tag.textColor, backgroundColor: tag.bgColor }} key={i}>{tag.text}</span>
                                    ))}
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <div className='blogs-page__bottom-section' ref={bottomSectionRef}>
                <h4 className='header'><span style={{fontWeight:"800"}}>All</span> Blog Posts</h4>
                <div className='blogs-page__bottom-section-main'>
                    {currentPosts.map((blog, i) => (
                        <BlogTemplate key={i} onclick={() => handleBlogClick(blog.slug)} imgHeight={'240px'} id={blog.id} width='384px' author={blog.author} title={blog.title} des={blog.des} tags={blog.tags} img={blog.compImg} />
                    ))}
                </div>
            </div>

            {/* Pagination */}
            <div className="pagination">
                <button onClick={handlePreviousPage} disabled={currentPage === 1}>Previous</button>
                <span>{currentPage} / {totalPages}</span>
                <button onClick={handleNextPage} disabled={currentPage === totalPages}>Next</button>
            </div>



        </motion.div>
  )
}

export default BlogsPage