import { Link } from 'react-router-dom';
import { FaqData } from '../../../utils/data';
import { useState } from 'react';

import AccordionOpen from '../../../assets/Home/FAQ/accordion-open-icon.svg';
import AccordionClose from '../../../assets/Home/FAQ/accordion-close-icon.svg';

import { motion } from 'framer-motion';


import './Faq.css';

const AccordionCard = ({data,selectedAccordion, onAccordionClick, i}) => {
    return (
        <div key={data.id} className='accordion-main' >
            <div className='accordion-main-title' onClick={() => onAccordionClick(i)}>
                <h4>{data.question}</h4>
                <img width={24} height={24} src={`${selectedAccordion === i ? AccordionClose : AccordionOpen}`} alt="" />
            </div>
            <div className={`${selectedAccordion === i ? "accordion-main-content" : "accordion-main-content-closed"}`}>
                <p style={{color: '#FCFCFD90'}}>
                    {data.answer} {(i === 4 || i === 0) &&
                        <Link
                            // onClick={() => window.scrollTo(0, 0)}
                            style={{color:"#FCFCFD90" ,fontWeight:"800", textDecoration:"underline"}}
                            className='accordion-main-link'
                            to='https://calendly.com/botgauge/30min'
                        >
                            here
                        </Link>
                    }
                </p>
            </div>
        </div>
    )
}

const Faq = () => {

    const [selectedAccordion, setSelectedAccordion] = useState(null)

    const onAccordionClick = (i) => {
        if (selectedAccordion === i) {
            return setSelectedAccordion(null)
        }

        setSelectedAccordion(i)
    }
    return (
        <div id='faq' className='faq-container'>
            <div className='faq-title'>
                <p>Frequently asked Questions</p>
            </div>
            <div className='faq-header'>
                <h4>FAQs</h4>
            </div>
            <p>Explore our FAQs for quick insights. Find answers to common queries, enhancing understanding and ensuring a seamless experience for you.</p>
            <div className='faq-main-accordion'>
                {FaqData.map((data, i) => (
                    <AccordionCard data={data} key={i} i={i} selectedAccordion={selectedAccordion} onAccordionClick={onAccordionClick} />
                ))}
            </div>
            {/* <Link className='light-btn' to='/'>Read more</Link> */}
        </div>
    )
}

export default Faq;
