import React from 'react'
import { TermsComp } from '../Terms/Terms'
import { privacyPolicyData } from '../../utils/data'
import { motion } from 'framer-motion'

const PrivacyPoilcy = () => {
  return (
    <motion.div
    initial={{ y: -10, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    exit={{ y: -10, opacity: 0 }}
    transition={{ ease:[0.76,0,0.24,1], duration:1 }}>
        <div className="feature-main terms-title">
            <h3>Privacy Policy of BotGauge</h3>
            {/* <p>Effective Date: 10th July 2024</p> */}
        </div>

        <div className='terms-container'>

            <div className='terms-title-top'>
                <h4 className='terms-title-text'>Privacy Policy Overview</h4>
                <p>Botgauge, Inc., a Delaware corporation ("Botgauge," "we," or "us"), has established this Privacy Policy to inform you of our policies and procedures regarding the collection, use, and disclosure of information, including personal information, from users of our services provided under the name Autify (the "Service"). This Privacy Policy applies solely to information that you provide to us in connection with the Service</p>
            </div>

            <div>
                <TermsComp data={privacyPolicyData} />
            </div>
        </div>
    </motion.div>
  )
}

export default PrivacyPoilcy