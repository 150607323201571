import BotFeatures from '../../components/Products/BotFeatures/botFeatures';
import BotgenFeature from '../../components/Products/BotgenFeature/BotgenFeature';
import ProductsHero from '../../components/Products/Hero/ProductsHero';
import './Products.css';
import GetInTouch from '../../components/Home/GetInTouch/GetInTouch';

import { motion } from 'framer-motion';

const Products = () => {
  return (
    <motion.div 
            initial={{ y: -10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ ease:[0.76,0,0.24,1], duration:1 }}
            className='products-container'>
        <ProductsHero />
        <BotgenFeature />
        <BotFeatures />
      <GetInTouch />
    </motion.div>
  )
}

export default Products